import { Button } from 'primereact/button'
import { RadioButton } from 'primereact/radiobutton'
import React, { useEffect, useState } from 'react'
import DateInput from '../../../components/inputs/DateInput'
import InputFile from '../../../components/inputs/InputFile'
import SelectInput from '../../../components/inputs/SelectInput'
import { showErrorMessage, showSuccessMessage, showWarnMessage } from '../../../components/utils/Message'
import CommonHelper from '../../../helpers/CommonHelper'
import AtpvService from '../../../services/atpv/AtpvService'
import VeiculoService from '../../../services/cadastro/VeiculoService'

function AssinarAtpve ({ veiculo }) {
  const [dataAssinatura, setDataAssinatura] = useState(new Date())
  const [tipoCrv, setTipoCrv] = useState({ codigo_tcr: 'D', descri_tcr: 'DIGITAL' })

  const [selectedFilePdf, setSelectedFilePdf] = useState(null)
  const [pdfFileName, setPdfFileName] = useState(null)

  const [selectedFileP7S, setSelectedFileP7S] = useState(null)
  const [p7sFileName, setP7sFileName] = useState(null)

  const [tiposCrv, setTiposCrv] = useState([])
  const [selectedTipoAssinatura, setSelectedTipoAssinatura] = useState({ key: 'ARC' })

  const tiposAssinatura = [{ name: 'Assinatura com reconhecimento em cartório', key: 'ARC' }, { name: 'Assinatura qualificada (Certificado)', key: 'AQC' }]

  useEffect(async () => {
    await getTiposCrv()
  }, [])

  async function getTiposCrv () {
    const tiposCrv = await VeiculoService.getTiposCrv()

    setTiposCrv(tiposCrv.filter(tipoCrv => tipoCrv.codigo_tcr === 'D' || tipoCrv.codigo_tcr === 'V'))
  }

  async function handleBaixarAtpve () {
    try {
      const responseRenave = await AtpvService.getAtpvUsado(veiculo.placa, veiculo.renavam)
      const pfdAtpvBase64 = await AtpvService.atualizarAtpv(responseRenave.data.pdfAtpvBase64)
      CommonHelper.downloadFile('data:application/pdf;base64,', pfdAtpvBase64, `ATPV-${veiculo.placa}.pdf`)
      showSuccessMessage('ATPV-e baixado com sucesso!')
    } catch (error) {
      showErrorMessage(error.message || 'Houve um erro ao baixar o ATPV-e')
    }
  }

  function handleChangeTipoAssinatura (tipoAssinatura) {
    setSelectedTipoAssinatura(tipoAssinatura)
  }

  function handleChangeTipoCrv (novoTipoCrv) {
    setTipoCrv(novoTipoCrv)
  }

  function uploadPdfFile (files) {
    getBase64(files[0], 'pdf')
    setPdfFileName(files[0].name)
  }

  function uploadP7SFile (files) {
    getBase64(files[0], 'p7s')
    setP7sFileName(files[0].name)
  }

  function getBase64 (file, tipoArquivo) {
    const reader = new FileReader()

    reader.readAsDataURL(file)

    reader.onload = function () {
      const base64 = reader.result.replace('data:application/pdf;base64,', '')

      if (tipoArquivo === 'pdf') {
        setSelectedFilePdf(base64)
      } else {
        setSelectedFileP7S(base64)
      }
    }

    reader.onerror = function (error) {
      console.log('Error: ', error)
      showErrorMessage('Ocorreu um erro ao tentar ler o arquivo selecionado!')
    }
  }

  async function enviarAtpvAssinado () {
    try {
      if (!selectedFilePdf) {
        showWarnMessage('Por favor insira o PDF do ATPV-e!')
        return
      }

      if (selectedTipoAssinatura.key === 'AQC' && !selectedFileP7S) {
        showWarnMessage('Por favor insira o P7S com a assinatura!')
        return
      }

      await AtpvService.enviarAssinatura(veiculo?.idest_vei || veiculo?.id, selectedFilePdf, selectedFileP7S, tipoCrv)
    } catch (error) {
      showErrorMessage(error.message || 'Ocorreu um erro inesperado ao tentar enviar o ATPV-e assinado')
    }
  }

  return (
    <div className="formgrid grid">
      <div className="field col-2"></div>
      <div className="field col-8">
        <div className="flex justify-content-center">
          <Button
            label="Download ATPV-E"
            className="mb-4"
            onClick={async () => handleBaixarAtpve()}
          />
        </div>
        <div className="formgrid grid">
          <div className="field col-3">
            <DateInput
              label="Data da assinatura"
              placeholder="Data da assinatura"
              value={dataAssinatura}
              onChange={(e) => setDataAssinatura(e.value)}
              className="inputfield w-full"
            />
          </div>
          <div className="field col-3">
            <SelectInput
              label="Tipo CRV"
              value={tipoCrv}
              options={tiposCrv}
              optionLabel="descri_tcr"
              placeholder="- Selecione -"
              onChange={(e) => handleChangeTipoCrv(e.target.value)}
              className="inputfield w-full"
              noFloatLabel
            />
          </div>
          <div className="field col-6 flex flex-column justify-content-center">
            {tiposAssinatura.map((tipoAssinatura) => {
              return (
                <div key={tipoAssinatura.key} className="mt-2">
                  <RadioButton
                    inputId={tipoAssinatura.key}
                    name="tipoAssinatura"
                    value={tipoAssinatura}
                    onChange={(e) => handleChangeTipoAssinatura(e.value)}
                    checked={selectedTipoAssinatura.key === tipoAssinatura.key}
                  />
                  <label htmlFor={tipoAssinatura.key}>{tipoAssinatura.name}</label>
                </div>
              )
            })}
          </div>
        </div>
        <div className="formgrid grid mt-3">
          <div className="field col-12 flex justify-content-center">
            <InputFile
              uploadHandler={(event) => uploadPdfFile(event.files)}
              label={{ label: pdfFileName || 'Selecione o arquivo atpv(pdf)' }}
              className="mr-2"
            />
            {selectedTipoAssinatura.key === 'AQC' && (
              <InputFile
                uploadHandler={(event) => uploadP7SFile(event.files)}
                label={{ label: p7sFileName || 'Selecione o arquivo assinado(p7s)', size: 34 }}
              />
            )}
          </div>
        </div>
        <div className="flex justify-content-center">
          <Button
            label="Enviar ATPV-E assinado"
            className="mt-3"
            onClick={async () => await enviarAtpvAssinado()}
          />
        </div>
      </div>
      <div className="field col-2"></div>
    </div>
  )
}

export default AssinarAtpve
